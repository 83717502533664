import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/joy";
import { useHistory } from "react-router";
import { createClient } from "@supabase/supabase-js";
import { Storage } from "./storage/storage";

const Balance: React.FC = () => {
  const history = useHistory();

  const [user, setUser] = useState({} as any);

  async function getUser() {
    const user = await Storage.user.get();
    setUser(user);
  }

  useEffect(() => {
    getUser();
  }, []);

  async function logout() {
    Storage.removeAll();
    history.push("/main");
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "top",
        padding: "15px",
      }}
    >
      <Box
        sx={{
          minWidth: "200 px",
          maxWidth: "500px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "top",
        }}
      >
        <Typography level="h1" sx={{ textAlign: "center" }}>
          Account Info for{" "}
          {user && user.phone && user.name && user.phone + " - " + user.name}
        </Typography>
        <Typography level="h2">Balance: ${user.balance}</Typography>
        <Typography level="h2">{user.points} Available Points</Typography>
        <Typography level="h2">{user.lt_points} Lifetime Points</Typography>
        <br />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "15px",
            width: "100%",
          }}
        >
          <Button
            variant="outlined"
            fullWidth
            onClick={() =>
              (window.location.href =
                "https://buy.stripe.com/00geUW6pE9ArfZK6op")
            }
          >
            Add Money
          </Button>
          {user && user.pass_url !== null && (
            <Button
              variant="outlined"
              fullWidth
              onClick={() => window.open(user.pass_url, "_blank")}
            >
              Add Pass to Phone
            </Button>
          )}
          {/* <Button variant="outlined" fullWidth>
            Start a Subscription
          </Button> */}
          <Button variant="outlined" fullWidth onClick={logout}>
            Logout
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Balance;
