import React, { useEffect, useState } from "react";
import { Box, Button, Input, Typography } from "@mui/joy";
import { useHistory } from "react-router";
import { createClient } from "@supabase/supabase-js";
import { Storage } from "./storage/storage";

const supabase = createClient(
  "https://yqvonjdulsvapcwniijk.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inlxdm9uamR1bHN2YXBjd25paWprIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDg4MTIwMDYsImV4cCI6MjAyNDM4ODAwNn0.Mj3DIzYavEQJUCCZOrTyf5ALUSXUS6xcJ6tA1MoRpy8"
);

const Home: React.FC = () => {
  const history = useHistory();

  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");

  const [lengthError, setLengthError] = useState(false);

  const [needSignup, setNeedSignup] = useState(false);

  const [loader, setLoader] = useState(false);

  async function login(phone: string) {
    setLoader(true);
    if (phone.length === 10 && parseFloat(phone) > 0) {
      const { data, error } = await supabase
        .from("users")
        .select("*")
        .eq("phone", parseInt(phone));

      if (data && data[0] && data[0].phone && !error) {
        setPhone("");
        setLoader(false);
        Storage.user.set(data[0]);
        Storage.loggedIn.set(true);
        history.push("/balance");
      } else if (!error) {
        setNeedSignup(true);
        setLoader(false);
      } else {
        console.error(error);
        setLoader(false);
      }
    } else {
      setLengthError(true);
      setLoader(false);
    }
  }

  useEffect(() => {
    if (setLengthError) {
      setTimeout(() => {
        setLengthError(false);
      }, 5000);
    }
  }, [setLengthError]);

  async function signup() {
    setLoader(true);
    const { data, error } = await supabase
      .from("users")
      .insert([{ phone: parseInt(phone), name: name }])
      .select("*");

    if (data && data[0].phone && !error) {
      setPhone("");
      setTimeout(() => {
        getNewUser(data[0].phone);
      }, 4000);
    } else {
      console.error(error);
      setLoader(false);
    }
  }

  async function getNewUser(phone: string) {
    const { data: user, error: uerror } = await supabase
      .from("users")
      .select("*")
      .eq("phone", parseInt(phone));

    if (user && user[0].phone && !uerror) {
      setLoader(false);
      Storage.loggedIn.set(true);
      Storage.user.set(user[0]);
      history.push("/balance");
    } else {
      console.error(uerror);
      setLoader(false);
    }
  }

  async function checkUser() {
    const user = await Storage.user.get();
    if (user && user.phone) {
      setPhone(user.phone.toString());
      login(user.phone.toString());
    }
  }

  useEffect(() => {
    checkUser();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "top",
        padding: "15px",
      }}
    >
      <Box
        sx={{
          minWidth: "200 px",
          maxWidth: "600px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "top",
        }}
      >
        <Typography level="h1" sx={{ textAlign: "center" }}>
          Welcome to Eagle's Landing MyPay.
        </Typography>
        <Typography level="h2" sx={{ textAlign: "center" }}>
          Please enter a student's phone number to continue.
        </Typography>
        {lengthError && (
          <Typography level="h3" sx={{ textAlign: "center", color: "red" }}>
            Please enter a valid 10-digit phone number.
          </Typography>
        )}
        <br />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            gap: "15px",
          }}
        >
          <Input
            type="number"
            placeholder="Phone Number"
            onChange={(e) => {
              const value = parseInt(e.target.value);
              if (value > 0 && value.toString().length === 10) {
                setPhone(value.toString());
              }
            }}
            fullWidth
          />
          {!needSignup && (
            <Button variant="outlined" fullWidth onClick={() => login(phone)}>
              Login
            </Button>
          )}
          {needSignup && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                gap: "15px",
              }}
            >
              <Input
                type="text"
                placeholder="Full Name"
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
              <Button variant="outlined" fullWidth onClick={signup}>
                Signup
              </Button>
              {loader && <Typography>Please wait...</Typography>}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
