//@ts-ignore
import { Preferences } from '@capacitor/preferences';

class StorageItem {
    constructor(private key: string) {}
  
    async set(value: any) {
      await Preferences.set({
        key: this.key,
        value: JSON.stringify(value)
      });
    }
  
    async get(): Promise<any> {
      const data = await Preferences.get({ key: this.key });
      return JSON.parse(data.value as string);
    }
  
    async remove() {
      await Preferences.remove({ key: this.key });
    }
  }
  
  export class Storage {
    static user = new StorageItem('user');

    static loggedIn = new StorageItem('loggedIn');

    static emp = new StorageItem('isEmp');
  
    static async removeAll() {
      await Preferences.clear();
    }
  }