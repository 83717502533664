import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import {
  StyledEngineProvider,
  CssVarsProvider,
  CssBaseline,
  Typography,
} from "@mui/joy";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("pk_test_51NbUMeRIHMDE49whEBwoQ5fsctZ9v3EZQeAMdUBRlTSRoEA66KM94vbNwhczZoynLhPnCblR87u63EAhBZrDTnsr00g9GPHGcB");

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <CssVarsProvider defaultMode="system">
        <Elements stripe={stripePromise}>
          <CssBaseline />
          <App />
        </Elements>
      </CssVarsProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
